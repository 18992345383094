import axios from 'axios';
import API_ENV from '@/config/api.config';
import { dashboard } from '@/mocks';

const {
  apiDashboardAnalytics,
  apiWarehouseAcceptance,
  apiRegionsOrdersDynamic,
  apiDashboardReputation,
  apiDashboardCardsImages,
  apiDashboardSeoValues,
  apiDashboardPromotions,
  apiDashboardBadReviewsReasons,
  apiDashboardPredictedSales,
  apiDashboardNmIdsForAI,
  apiDashboardAddNmIdsForAI,
} = API_ENV;

function isEmptyValue(value) {
  if (value == null) return true;

  let isEmptyValue = true;
  for (const key in value) {
    if (value[key]) isEmptyValue = false;
  }

  return isEmptyValue;
}

export default {
  state: {
    financeInfo: {
      goodsMarginInfo: null,
      abcChart: null,
    },
    turnover: {
      labels: ['>240 д.', '>120 д.', '>60 д.', '>30 д.', '<30 д.'],
      datasets: [
        {
          backgroundColor: ['#8764EB', '#EB9564', '#93D75D', '#5D7FD7', '#EB648C', '#5E91C7'],
          data: [],
          borderColor: '#fff',
          borderWidth: 2,
          borderRadius: 6,
        },
      ],
    },
    wbStocks: {
      labels: ['OOS', '< 14 дней', '< 30 дней', '> 30 дней'],
      datasets: [
        {
          backgroundColor: [
            '#c13030',
            '#8764EB',
            '#EB9564',
            '#93D75D',
            '#5D7FD7',
            '#EB648C',
            '#5E91C7',
          ],
          data: [],
          borderColor: '#fff',
          borderWidth: 2,
          borderRadius: 6,
        },
      ],
    },
    ownStocks: {
      labels: ['< 7 дней', '< 14 дней', '> 14 дней', 'OOS'],
      datasets: [
        {
          data: [],
          backgroundColor: ['#49AAE0', '#97E3D5', '#ff6f00', '#F47560'],
          hoverOffset: 4,
        },
      ],
    },
    turnoverDynamics: null,
    reputationInfo: null,
    reputatioSalesDynamic: {},
    predictedSales: null,
    seoRecomendations: null,
    isExampleData: false,
    nmIdsForAI: null,
    modalType: 'seo_name',
  },
  getters: {
    getDashboardFinanceInfo: state => state.financeInfo,
    getDashboardTurnoverInfo: state => state.turnover,
    getDashboardWbStocksInfo: state => state.wbStocks,
    getDashboardOwnStocksInfo: state => state.ownStocks,
    getDashboardLogisticInfo: state => {
      return {
        turnover: state.turnover,
        wbStocks: state.wbStocks,
        ownStocks: state.ownStocks,
      };
    },
    getDashboardReputationInfo: state => state.reputationInfo,
    getDashboardTurnoverDynamics: state => state.turnoverDynamics,
    getDashboardReputatioSalesDynamic: state => state.reputatioSalesDynamic,
    getDashboardPredictedSales: state => state.predictedSales || [],
    getDashboardSeoRecomendations: state => state.seoRecomendations || [],
    getDashboardSeoRecomendationsModalType: state => state.modalType,
    getDashboardIsExampleData: state => state.isExampleData,
    getDashboardNmIdsForAI: state => state.nmIdsForAI,
    getDashboardPredictedSalesChart: state => {
      const sales = state.predictedSales;
      const data = [0, 0, 0];

      if (!sales) return null;

      for (const sale of sales) {
        switch (sale.price_status) {
          case 'Заниженная цена':
            data[0]++;
            break;
          case 'Завышенная цена':
            data[1]++;
            break;
          case 'Нормальная цена':
            data[2]++;
            break;
        }
      }

      const chartData = {
        labels: ['Заниженная цена', 'Завышенная цена', 'Нормальная цена'],
        datasets: [
          {
            backgroundColor: ['#007aff', '#FA6B0E', '#9CE4D5'],
            data,
            borderColor: '#fff',
            borderWidth: 2,
            borderRadius: 6,
          },
        ],
      };

      return chartData;
    },
  },
  mutations: {
    setDashboardFinanceInfo(state, data) {
      state.financeInfo = { ...state.financeInfo, ...data };
    },
    setDashboardFinanceAbcChart(state, data) {
      const abcData = {
        labels: ['Группа A', 'Группа B', 'Группа C'],
        dataset: {
          data,
          backgroundColor: ['#49AAE0', '#97E3D5', '#F47560'],
          textColor: ['#fff', '#214D45', '#fff'],
        },
      };

      state.financeInfo.abcChart = abcData;
    },
    setDashboardTurnoverChartData(state, data) {
        state.turnover = { ...state.turnover , datasets: [{ ...state.turnover.datasets[0] , data: data  }] };
    },
    setDashboardWbStocksChartData(state, data) {
        state.wbStocks = { ...state.wbStocks , datasets: [{ ...state.wbStocks.datasets[0] , data: data  }] };
    },
    setDashboardOwnStocksChartData(state, data) {
        state.ownStocks = { ...state.ownStocks , datasets: [{ ...state.ownStocks.datasets[0] , data: data  }] };
    },
    setDashboardTurnoverDynamics(state, data) {
      state.turnoverDynamics = data;
    },
    setDashboardReputationInfo(state, data) {
      if (!Object.keys(data).length) {
        data = {
          last_feedbacks: [],
          rating_dynamics: [],
          ratings_count: [],
        };
      }

      state.reputationInfo = data;
    },
    setReputationSalesDynamic(state, { returnRates, salesDynamic, returnDynamic }) {
      if (!returnRates) return;

      const converDate = date => new Date(date).toLocaleDateString('ru');

      const labels = [];
      const returnData = [];
      const salesData = [];

      for (const data of returnRates) {
        labels.unshift(converDate(data.week));
        returnData.unshift(data.total_returns);
        salesData.unshift(data.average_buyout_rate);
      }

      const returnChart = {
        labels,
        datasets: [
          {
            label: 'Динамика возвратов',
            data: returnData,
          },
        ],
      };

      const salesChart = {
        labels,
        datasets: [
          {
            label: 'Процент выкупа',
            data: salesData,
          },
        ],
      };

      state.reputatioSalesDynamic = {
        salesDynamic: +salesDynamic.toFixed(1),
        returnDynamic: +returnDynamic.toFixed(1),
        returnChart,
        salesChart,
      };
    },
    setDashboardPredictedSales(state, sales) {
      state.predictedSales = sales;
    },
    setDashboardIsExampleData(state, isExample) {
      state.isExampleData = isExample;
    },
    setDashboardSeoRecomendations(state, data) {
      state.seoRecomendations = data;
    },
    setDashboardMockData(state) {
      for (const key in dashboard) {
        const stateValue = state[key];
        const mockData = dashboard[key];

        if (isEmptyValue(stateValue)) state[key] = mockData;
      }

      if (!state.predictedSales) state.predictedSales = dashboard.predictedSales;
    },
    setDashboardNmIdsForAI(state, data) {
      state.nmIdsForAI = data;
    },
    setDashboardSeoRecomendationsModalType(state, type) {
      state.modalType = type;
    },
  },
  actions: {
    async getDashboardFinanceData({ commit }) {
      try {
        const result = (await axios.get(apiDashboardAnalytics)).data;

        for (const row of result.Month.negative_income_serializer)
          row.wbComisionPerUnit = Math.round(
            +row.kvw_sales_commission_per_unit + +row.vw_nds_per_unit,
          );

        const financeInfo = {
          lowMarj: result.Month.products_margin_lte,
          soldInNeg: result.Month.negative_income_serializer,
          ordersPerDay: {
            goods: result.Orders_day.detail_orders,
            dynamic: result.Orders_day.difference_orders.percentage_difference_total_per_period,
            salesSum: Math.round(result.Orders_day.order_info.total_per_period),
          },
        };
        commit('setDashboardFinanceInfo', { goodsMarginInfo: financeInfo });

        const { total_purchase_price_a, total_purchase_price_b, total_purchase_price_c } =
          result.Month;
        commit('setDashboardFinanceAbcChart', [
          total_purchase_price_a,
          total_purchase_price_b,
          total_purchase_price_c,
        ]);

        const salesDynamicData = {
          returnRates: result.Week.for_graf_rate_return,
          salesDynamic: result.Week.buyout_rate_percentage_change,
          returnDynamic: result.Week.return_count_percentage_change,
        };
        commit('setReputationSalesDynamic', salesDynamicData);

        return result.data;
      } catch (error) {
        console.error(error);
        const errorText = error.response.data?.error;
        commit('showAlert', errorText);
      }
    },
    getDashboardLogisticsInfo({ commit, dispatch }) {
      dispatch('getDashboardTurnover');
      dispatch('getDashboardWbStocks');
      dispatch('getDashboardOwnStocks');
    },
    async getDashboardTurnover({ commit, dispatch }) {
      const turnover = await dispatch('getPlanningDeliveryTurnoverDynamics');

      const { more_then_240, more_then_120, more_then_60, more_then_30, less_then_30 } = turnover;
      const chartData = [more_then_240, more_then_120, more_then_60, more_then_30, less_then_30];

      commit('setDashboardTurnoverChartData', chartData);
      commit('setDashboardTurnoverDynamics', turnover);
    },
    async getDashboardWbStocks({ commit, dispatch }) {
      const data = await dispatch('getPlanningDeliveryQuantity');

      const { OOS_count, less_than_14_count, less_than_30_count, more_than_30_count } = data;
      const chartData = [OOS_count, less_than_14_count, less_than_30_count, more_than_30_count];

      commit('setDashboardWbStocksChartData', chartData);
    },
    async getDashboardOwnStocks({ commit, dispatch }) {
      const data = await dispatch('getStockQuantity');

      const { less_than_7_count, less_than_14_count, more_than_14_count, out_of_stock } = data;
      const chartData = [less_than_7_count, less_than_14_count, more_than_14_count, out_of_stock];

      commit('setDashboardOwnStocksChartData', chartData);
    },
    async getWarehouseAcceptance({ commit }) {
      try {
        const result = await axios.get(apiWarehouseAcceptance);

        return result.data;
      } catch (error) {
        const errorText = error.response.data?.error;
        commit('showAlert', errorText);
      }
    },
    async getDashboardRegionsOrders({ commit }) {
      try {
        const result = await axios.get(apiRegionsOrdersDynamic);

        return result.data;
      } catch (error) {
        const errorText = error.response.data?.error;
        commit('showAlert', errorText);
      }
    },
    async getReputationInfo({ commit }) {
      try {
        const result = (await axios.get(apiDashboardReputation)).data;

        commit('setDashboardReputationInfo', result);

        return result;
      } catch (error) {
        const errorText = error.response.data?.error;
        commit('showAlert', errorText);
      }
    },
    async getDashboardCardsImages({ commit }) {
      try {
        const result = (await axios.get(apiDashboardCardsImages)).data;

        return result;
      } catch (error) {
        const errorText = error.response.data?.error;
        commit('showAlert', errorText);
      }
    },
    async getDashboardSeoValues({ commit }) {
      try {
        const result = (await axios.get(apiDashboardSeoValues)).data;

        commit('setDashboardSeoRecomendations', result.details);

        const seoValues = {};

        for (const key in result) seoValues[key] = Math.round(result[key]);

        return seoValues;
      } catch (error) {
        const errorText = error.response.data?.error;
        commit('showAlert', errorText);
      }
    },
    async getDashboardPromotions({ commit }) {
      try {
        const promotionsData = (await axios.get(apiDashboardPromotions)).data;

        const todayDate = new Date();
        const promotions = {
          goingAndGood: [],
          goingAndBad: [],
          notGoingAndBad: [],
          length: 0,
        };

        for (const promoName in promotionsData) {
          const promosSKUs = promotionsData[promoName];

          for (const promosSKU of promosSKUs) {
            const startDate = new Date(promosSKU.start_date);
            const endDate = new Date(promosSKU.end_date);

            if (todayDate > endDate) continue;

            const options = {
              month: 'long',
              day: 'numeric',
            };

            promosSKU.name = promoName;
            promosSKU.start_date = startDate.toLocaleString('ru', options);
            promosSKU.end_date = endDate.toLocaleString('ru', options);

            const isGoing = todayDate >= startDate;
            const isGoodPrice = promosSKU.current_retail_price <= promosSKU.planned_action_price;

            if (isGoing && isGoodPrice) promotions.goingAndGood.push(promosSKU);
            else if (!isGoing && !isGoodPrice) promotions.notGoingAndBad.push(promosSKU);
            else if (isGoing && !isGoodPrice) promotions.goingAndBad.push(promosSKU);
            else promotions.length--;

            promotions.length++;
          }
        }

        return promotions;
      } catch (error) {
        console.error(error);
        const errorText = error.response.data?.error;
        commit('showAlert', errorText);
        throw error;
      }
    },
    async getDashboardBadReviewsReasons({ commit }) {
      try {
        const result = (await axios.get(apiDashboardBadReviewsReasons)).data;

        const reasons = [];

        for (const badReview of result) {
          if (!badReview.conclusion) continue;

          const reason = {
            ...badReview,
            conclusion: badReview.conclusion.split('\n'),
          };

          reasons.push(reason);
        }

        return reasons;
      } catch (error) {
        console.error(error);
        const errorText = error.response.data?.error;
        commit('showAlert', errorText);
        throw error;
      }
    },
    async getDashboardPredictedSalesData({ commit }) {
      try {
        const salesData = (await axios.get(apiDashboardPredictedSales)).data;

        commit('setDashboardPredictedSales', salesData);

        return salesData;
      } catch (error) {
        console.log({ error });
        const errorText = error.response.data?.error;
        commit('showAlert', errorText);
      }
    },
    async getDashboardNmIdsForAI({ commit }) {
      try {
        const result = await axios.get(apiDashboardNmIdsForAI);
        const nmIdsForAI = result.data;

        commit('setDashboardNmIdsForAI', nmIdsForAI);

        return nmIdsForAI;
      } catch (error) {
        const errorText = error.response?.data.error;
        commit('showAlert', errorText);
        throw error;
      }
    },
    async addDashboardNmIdsForAI({ commit }, { nm_ids, seo_info, seo_reputation }) {
      try {
        const params = {
          seo_reputation,
          seo_info,
        };

        const body = { nm_ids };

        const result = await axios.post(apiDashboardAddNmIdsForAI, body, { params });

        return result.data;
      } catch (error) {
        const errorText = error.response?.data.error;
        commit('showAlert', errorText);
        throw error;
      }
    },
    initializeDashboardMockData({ commit, dispatch }) {
      commit('setDashboardMockData');
      commit('setTotalStocksCost', 521231);
      commit('setDashboardFinanceInfo', dashboard.financeInfo);
      dispatch('initializeAnalyticMockData');
    },
  },
};
