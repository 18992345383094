import axios from 'axios';
import API_ENV from '@/config/api.config';
import getCardPosterUrl from '@/utils/getCardPosterUrl';

const { apiAdCampaign, apiAdStats, apiAdCampaignFinance } = API_ENV,
  apiAdCampaignCategories = apiAdCampaign + 'subjects/',
  apiAdCapmaignList = apiAdCampaign + 'list/',
  apiAdCapmaignProducts = apiAdCampaign + 'products/',
  apiAdCapmaignImporty = apiAdCampaign + 'parse/',
  apiAdCapmaignAction = apiAdCampaign + 'action/';

export default {
  state: {
    adCampaignsData: {},
  },
  getters: {
    getAdCampaigData: ({ adCampaignsData }) => adCampaignsData,
  },
  mutations: {
    editAdCampaignsData(state, obj) {
      state.adCampaignsData = obj;
    },
    editAdCampaignsDataKey(state, obj) {
      const { id, key, value } = obj;
      const findedElement = state.adCampaignsData.results.find(item => item.id === id);
      findedElement[key] = value;
    },
    deleteCampaignsByIds(state, ids) {
      state.adCampaignsData.results = state.adCampaignsData.results.filter(
        campaign => !ids.includes(campaign.id),
      );
    },
    changeCampaignsStatusByIds(state, { action, campaignIds }) {
      const actions = {
        finish: {
          statusId: 7,
          status: 'Показы завершены',
        },
        start: {
          statusId: 9,
          status: 'Запущена',
        },
        pause: {
          statusId: 11,
          status: 'Приостановлено',
        },
      };

      for (const id of campaignIds) {
        const campaign = state.adCampaignsData.results.find(el => el.id == id);

        if (!campaign) continue;

        campaign.status = actions[action].status;
        campaign.statusId = actions[action].statusId;
        campaign.isChecked = false;
      }

      state.adCampaignsData = JSON.parse(JSON.stringify(state.adCampaignsData));
    },
  },
  actions: {
    async importAd({ commit }, obj) {
      try {
        const { data } = await axios.post(apiAdCapmaignImporty, obj);

        return data;
      } catch (error) {
        const errorText = error.response?.data.detail;
        commit('showAlert', errorText);
      }
    },
    async updateActivityAd({ commit }, id) {
      try {
        const { data } = await axios.put(`${apiAdCampaign}${id}/update_activity/`);
        console.log('data', data);
      } catch (error) {}
    },
    async getAdCampaignCategories({ commit }, company_id) {
      try {
        const { data } = await axios.get(apiAdCampaignCategories + company_id);

        return data;
      } catch (error) {
        commit('showAlert', error.response.data.detail);
        throw error;
      }
    },
    async getAdCampaignProducts({ commit }, { companyId, subjectIds }) {
      if (!Array.isArray(subjectIds)) subjectIds = [subjectIds];

      try {
        const { data } = await axios.get(
          `${apiAdCapmaignProducts}${companyId}/${subjectIds.join(',')}/`,
        );

        const goods = data.map(good => ({ ...good, image: getCardPosterUrl(good.article) }));

        return goods;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async fetchAdCampaignList({ commit }, obj) {
      try {
        const { data } = await axios.get(`${apiAdCapmaignList}`, { params: obj });
        // Статус выбранного элемента в таблице
        data.results.forEach(item => {
          item.isChecked = false;
        });
        commit('editAdCampaignsData', data);
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async createAdCampaign({ commit }, { body, type }) {
      try {
        const { data } = await axios.post(`${apiAdCampaign}${type}/`, body);

        return data;
      } catch (error) {
        const detail = error.response.data.detail;

        if (detail.startsWith('РК создана на WILDBERRIES'))
          return { created: true, message: detail };

        const errorText = JSON.parse(detail)?.error;

        commit('showAlert', errorText);
        throw error;
      }
    },
    async changeAdCampaign({ commit }, { body, type, id }) {
      try {
        const { data } = await axios.put(`${apiAdCampaign}${type}/${id}/`, body);

        return data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async changeAdCampaignItemsCpm({ commit }, { type, id, items }) {
      try {
        let result;
        if (type == 'auto') {
          result = await axios.put(`${apiAdCampaign}${type}/${id}/cpm/`, { items });
        } else {
          const promises = [];

          for (const item of items) {
            const body = {
              newCpm: item.bet,
              campaignItem: item.id,
              instrument: 6,
            };
            const promise = axios.put(`${apiAdCampaign}${type}/${id}/cpm/`, body);

            promises.push(promise);
          }

          result = await Promise.all(promises);
        }

        return result;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async changeAdCampaignStatus({ commit }, { action, campaignIds }) {
      const body = { action, campaignIds };

      try {
        const { data } = await axios.patch(apiAdCapmaignAction, body);

        if (action == 'delete') commit('deleteCampaignsByIds', campaignIds);
        else if (action != 'sync') commit('changeCampaignsStatusByIds', { action, campaignIds });

        return data;
      } catch (error) {
        const actions = {
          finish: 'завершить',
          start: 'запустить',
          pause: 'приостановить',
          sync: 'синхронизировать',
          delete: 'удалить',
        };

        const errorText =
          error?.response?.data?.detail || 'Данные кампании невозможно ' + actions[action];
        commit('showAlert', errorText);
        throw error;
      }
    },
    async getAdCampaignById({ commit }, { id, type }) {
      try {
        const { data } = await axios.get(`${apiAdCampaign}${type}/${id}/`);

        return data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async fetchCampaignStats({ commit }, obj) {
      try {
        const { id, ...rest } = obj;

        const { data } = await axios.get(`${apiAdStats}${id}/charts/`, { params: rest });
        return data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async fetchCampaignTableStats({ commit }, obj) {
      try {
        const { id, ...rest } = obj;

        const { data } = await axios.get(`${apiAdStats}${id}/`, { params: rest });
        return data;
      } catch (error) {
        commit('showAlert');
        throw error;
      }
    },
    async fillAdCampaignBudget({ commit }, { id, body }) {
      try {
        const result = await axios.put(`${apiAdCampaign}${id}/budget/replenish/`, body);

        return result.data;
      } catch (error) {
        const errorText = JSON.parse(error.response.data.detail)?.error;

        commit('showAlert', errorText);
        throw error;
      }
    },
    async getAdCampaignBudgetTypes({ commit }, companyId) {
      try {
        const { data } = await axios.get(`${apiAdCampaignFinance}${companyId}/balance/`);

        const bTypes = {
          balance: { value: 0, name: 'Счет' },
          net: { value: 1, name: 'Баланс' },
          bonus: { value: 3, name: 'Бонусы' },
        };

        const bTypesArr = [];

        for (const type in bTypes) {
          const bType = bTypes[type];
          bType.name += ` (${data[type].toLocaleString('ru')} ₽)`;
          bTypesArr.push(bType);
        }

        return bTypesArr;
      } catch (error) {
        const errorText = error.response?.data.detail;
        commit('showAlert', errorText);
        throw error;
      }
    },
  },
};
