`
<template>
  <div class="sidebar">
    {{ fillList }}
    <div class="sidebar__closeBtn" v-if="isMobile" @click="$emit('input', false)">
      <Plus :width="25" />
    </div>
    <div class="sidebar__total-wrapper">
      <div class="sidebar__container">
        <router-link to="/" class="sidebar__logo">
          <img src="@/assets/img/logo.png" alt="Logo" />
        </router-link>
      </div>
      <nav class="sidebar__navigation">
        <h3 class="sidebar__navigation-title">НАВИГАЦИЯ</h3>
        <v-list class="sidebar__navigation">
          <component
            v-for="(item, index) of listItems"
            :is="item.hasChild ? 'div' : item.outLink ? 'a' : 'router-link'"
            :key="index"
            tag="a"
            class="sidebar__navigation-item"
            :class="{
              open: item.open,
              active: item.hasActiveChild || checkActiveChildren(item),
            }"
            @click="item.open = !item.open"
            active-class="active"
            :to="item.to"
            :href="item.outLink ? item.to : ''"
            :target="item.outLink ? '_blank' : undefined"
          >
            <div
              class="sidebar__navigation-title-box"
              @click="item.hasChild || activeParent(index, item.hasChild, item.nameItem)"
            >
              <span class="sidebar__navigation-icon" v-html="item.iconURL"></span>
              {{ item.nameItem }}
              <AI
                v-if="item.hasAI"
                :class="{ hasChild: item.hasChild }"
                class="sidebar__navigation-ai sidebar__navigation-icon"
              />
              <span class="arrow" v-if="item.hasChild">
                <svg
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.943573 0.538702C0.674034 0.731833 0.630333 1.0841 0.845964 1.32551L3.86695 4.70771L0.845964 8.0899C0.630333 8.33131 0.674034 8.68358 0.943573 8.87671C1.21311 9.06984 1.60642 9.0307 1.82205 8.78929L5.15538 5.0574C5.33799 4.85296 5.33799 4.56246 5.15538 4.35801L1.82205 0.626126C1.60642 0.384713 1.21311 0.345572 0.943573 0.538702Z"
                    fill="#3A416F"
                    stroke="#3A416F"
                    stroke-width="0.4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>

            <div
              class="sidebar__navigation-child-box"
              v-if="item.hasChild"
              @click="activeParent(index)"
              @click.stop
            >
              <router-link
                class="sidebar__navigation-child-item"
                :class="el.class"
                v-for="(el, index) of item.childrenItems"
                :key="index"
                tag="a"
                active-class="active"
                @click.native="onRouteChange(el.name)"
                :to="`${el.childURL}`"
              >
                {{ el.name }}
                <span v-if="el.beta">beta</span>
                <component v-if="el.iconComponent" :is="el.iconComponent" />
              </router-link>
            </div>
          </component>
        </v-list>
      </nav>
    </div>
    <div class="sidebar-footer">
      <div class="sidebar-footer__box">
        <div
          v-for="(item, index) in footerLinks"
          :key="index"
          @click="item.action"
          class="sidebar__navigation-title-box sidebar-footer__item"
        >
          <span v-html="item.icon" class="sidebar__navigation-icon"></span> {{ item.name }}
        </div>
      </div>
    </div>
    <CModal v-model="isModalVisible" @closeModal="closeModal">
      <WbCreateAccount @createDone="createDone" @deleteCabinet="closeModal" :cabinet="cabinet" />
    </CModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Plus from '@/components/UI/icons/Plus.vue';
import { RobotIcon, LockIcon, AI } from '@/components/UI/icons';
import CModal from '@/components/UI/CModal.vue';
import WbCreateAccount from '@/pages/PersonalAccount/WbCreateAccount.vue';

export default {
  name: 'SideBar',
  props: {
    isMobile: Boolean,
    isModalCreateAccountVisible: Boolean,
  },
  components: { Plus, CModal, RobotIcon, AI, LockIcon, WbCreateAccount },
  data() {
    return {
      name: '',
      rate: '',
      userImage: '',
      listItems: [],
      marker: true,
      showLimits: false,
      showNotifications: false,
      isModalVisible: false,
      cabinet: null,
      footerLinks: [
        {
          name: 'Тарифы',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M16.1466 7.13558L14.5896 8.69258C13.8105 8.06936 12.8223 7.69669 11.747 7.69669C9.23173 7.69669 7.19272 9.73569 7.19272 12.2509C7.19272 14.7662 9.23173 16.8052 11.747 16.8052C14.2622 16.8052 16.3012 14.7662 16.3012 12.2509C16.3012 11.5261 16.1319 10.8407 15.8306 10.2324L17.4379 8.62504C18.1065 9.67226 18.494 10.9163 18.494 12.2509C18.494 15.9772 15.4732 18.998 11.747 18.998C8.02068 18.998 4.99994 15.9772 4.99994 12.2509C4.99994 8.52465 8.02068 5.50391 11.747 5.50391C13.428 5.50391 14.9654 6.11865 16.1466 7.13558Z" fill="#C4C6D4"/>
            <path d="M13.8683 9.41605C13.277 8.97318 12.5426 8.71086 11.747 8.71086C9.79066 8.71086 8.20477 10.2968 8.20477 12.2531C8.20477 14.2093 9.79066 15.7952 11.747 15.7952C13.7033 15.7952 15.2891 14.2093 15.2891 12.2531C15.2891 11.813 15.2089 11.3916 15.0622 11.0028L13.5891 12.4759C13.4791 13.3956 12.6963 14.1085 11.747 14.1085C10.7222 14.1085 9.89153 13.2778 9.89153 12.2531C9.89153 11.2283 10.7222 10.3976 11.747 10.3976C12.0754 10.3976 12.384 10.483 12.6516 10.6327L13.8683 9.41605Z" fill="#3A416F"/>
            <path d="M18.8518 5.86384L12.5819 12.1337C12.5874 12.1727 12.5903 12.2125 12.5903 12.2531C12.5903 12.7188 12.2127 13.0964 11.7469 13.0964C11.2811 13.0964 10.9035 12.7188 10.9035 12.2531C10.9035 11.7873 11.2811 11.4097 11.7469 11.4097C11.7874 11.4097 11.8273 11.4125 11.8663 11.4181L18.1361 5.14821C18.3337 4.9506 18.6541 4.9506 18.8518 5.14821C19.0494 5.34583 19.0494 5.66623 18.8518 5.86384Z" fill="#3A416F"/>
          </svg>`,
          action: () => {
            this.$router.push('/rates');
          },
        },
        {
          name: 'Подключить кабинет',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path opacity="0.4" d="M5 6.34311C5 5.60133 5.60133 5 6.34311 5H9.64939C10.3912 5 10.9925 5.60133 10.9925 6.34311V9.64939C10.9925 10.3912 10.3912 10.9925 9.64939 10.9925H6.34311C5.60133 10.9925 5 10.3912 5 9.64939V6.34311Z" fill="#3A416F"/>
            <path opacity="0.4" d="M13.0076 14.3509C13.0076 13.6091 13.6089 13.0078 14.3507 13.0078H17.657C18.3987 13.0078 19.0001 13.6091 19.0001 14.3509V17.6572C19.0001 18.399 18.3987 19.0003 17.657 19.0003H14.3507C13.6089 19.0003 13.0076 18.399 13.0076 17.6572V14.3509Z" fill="#3A416F"/>
            <circle cx="16.0038" cy="7.99625" r="2.99625" fill="#3A416F"/>
            <circle cx="7.99625" cy="16.0041" r="2.99625" fill="#3A416F"/>
          </svg>`,
          action: async () => {
            try {
              this.cabinet = await this.createWbTokens();
              this.isModalVisible = true;
            } catch (error) {}
          },
        },
        {
          name: 'Реферальная программа',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path opacity="0.4" d="M12.9494 8.5L10.0291 8.5L9.04839 7.37507C8.08698 6.27234 9.20856 4.62408 10.6122 5.07689L11.2617 5.2864C11.4095 5.3341 11.569 5.3341 11.7169 5.2864L12.3663 5.07689C13.77 4.62408 14.8916 6.27234 13.9302 7.37507L12.9494 8.5Z" fill="#C4C6D4"/>
            <path opacity="0.4" d="M7.06911 10.748C7.61705 8.99463 9.24091 7.80078 11.0779 7.80078H11.9023C13.7393 7.80078 15.3632 8.99463 15.9111 10.748L16.7861 13.548C17.6313 16.2525 15.6108 19.0008 12.7773 19.0008H10.2029C7.36944 19.0008 5.34895 16.2525 6.19411 13.548L7.06911 10.748Z" fill="#C4C6D4"/>
            <path d="M11.4893 17.5984C11.4893 16.8252 12.1161 16.1984 12.8893 16.1984H17.0893C17.8625 16.1984 18.4893 16.8252 18.4893 17.5984C18.4893 18.3716 17.8625 18.9984 17.0893 18.9984H12.8893C12.1161 18.9984 11.4893 18.3716 11.4893 17.5984Z" fill="#3A416F"/>
            <path d="M11.4893 14.7984C11.4893 14.0252 12.1161 13.3984 12.8893 13.3984H16.6226H17.0893C17.8625 13.3984 18.4893 14.0252 18.4893 14.7984C18.4893 15.5716 17.8625 16.1984 17.0893 16.1984H12.8893C12.1161 16.1984 11.4893 15.5716 11.4893 14.7984Z" fill="#3A416F"/>
            </svg>`,
          action: () => {
            this.$router.push('/referals');
          },
        },
      ],
    };
  },
  methods: {
    ...mapActions(['getNotifications', 'readAllNotifications', 'createWbTokens', 'removeWbTokens']),
    createDone() {
      this.isModalVisible = false;
    },
    checkActiveChildren(item) {
      if (!item.childrenItems) return false;
      const check = item.childrenItems?.findIndex(
        el => this.$route.path.indexOf(el.childURL) !== -1,
      );

      return check !== -1;
    },
    async closeModal() {
      await this.removeWbTokens(this.cabinet.id);
      this.isModalVisible = false;
    },
    activeParent(index, hasChild, nameItem) {
      for (let item of this.listItems) {
        if (item.hasActiveChild) item.hasActiveChild = false;
      }
      if (this.listItems[index].hasChild) this.listItems[index].hasActiveChild = true;

      if (!hasChild) {
        this.$emit('changeRouteName', nameItem);
      }
    },
    onRouteChange(name) {
      if (this.isMobile) {
        this.$emit('input', false);
      }

      this.$emit('changeRouteName', name);
    },
    async editModalVisible() {
      this.cabinet = await this.createWbTokens();
      this.isModalVisible = true;
    },
  },
  computed: {
    ...mapGetters(['userInfo']),
    fillList() {
      this.listItems = [
        {
          iconURL: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.7">
                            <circle cx="13.0007" cy="2.9987" r="1.66667" fill="#3A416F"/>
                            <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M10.9189 1.33203H3.66732C2.37865 1.33203 1.33398 2.3767 1.33398 3.66536V12.332C1.33398 13.6207 2.37865 14.6654 3.66732 14.6654H12.334C13.6226 14.6654 14.6673 13.6207 14.6673 12.332V5.08049C14.2108 5.44646 13.6313 5.66536 13.0007 5.66536C11.5279 5.66536 10.334 4.47146 10.334 2.9987C10.334 2.36807 10.5529 1.78856 10.9189 1.33203Z" fill="#3A416F" opacity="0.4"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.66732 5.16602C6.20708 5.16602 5.83398 5.53911 5.83398 5.99935V6.66602C5.83398 7.67854 5.01317 8.49935 4.00065 8.49935H3.33398C3.05784 8.49935 2.83398 8.27549 2.83398 7.99935C2.83398 7.72321 3.05784 7.49935 3.33398 7.49935H4.00065C4.46089 7.49935 4.83398 7.12625 4.83398 6.66602V5.99935C4.83398 4.98683 5.6548 4.16602 6.66732 4.16602C7.67984 4.16602 8.50065 4.98683 8.50065 5.99935V9.99935C8.50065 10.4596 8.87375 10.8327 9.33398 10.8327C9.79422 10.8327 10.1673 10.4596 10.1673 9.99935V9.33268C10.1673 8.32016 10.9881 7.49935 12.0007 7.49935H12.6673C12.9435 7.49935 13.1673 7.72321 13.1673 7.99935C13.1673 8.27549 12.9435 8.49935 12.6673 8.49935H12.0007C11.5404 8.49935 11.1673 8.87245 11.1673 9.33268V9.99935C11.1673 11.0119 10.3465 11.8327 9.33398 11.8327C8.32146 11.8327 7.50065 11.0119 7.50065 9.99935V5.99935C7.50065 5.53911 7.12756 5.16602 6.66732 5.16602Z" fill="#3A416F"/>
                            </g>
                        </svg>`,
          nameItem: 'Дашборд рекомендаций',
          to: '/dashboard',
          hasAI: true,
        },
        {
          iconURL: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7089 14.7317C16.0106 13.3974 17.1992 11.3989 17.1992 9.79989C17.1992 7.14898 15.0054 5 12.2993 5C9.59317 5 7.39941 7.14898 7.39941 9.79989C7.39941 11.3989 8.58796 13.3974 9.88965 14.7317C10.7459 15.6095 11.6512 16.1997 12.2993 16.1997C12.9474 16.1997 13.8527 15.6095 14.7089 14.7317ZM12.2993 11.2999C13.0725 11.2999 13.6993 10.6731 13.6993 9.89989C13.6993 9.1267 13.0725 8.49992 12.2993 8.49992C11.5261 8.49992 10.8993 9.1267 10.8993 9.89989C10.8993 10.6731 11.5261 11.2999 12.2993 11.2999Z" fill="#3A416F"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.83872 15.1445C7.12864 15.52 6 16.1658 6 16.8994C6 18.0592 8.82054 18.9994 12.2999 18.9994C15.7792 18.9994 18.5997 18.0592 18.5997 16.8994C18.5997 16.1658 17.4711 15.52 15.761 15.1445C15.6619 15.2545 15.5618 15.3613 15.4611 15.4646C14.9905 15.947 14.4848 16.3734 13.9805 16.6866C13.4997 16.9851 12.9109 17.2494 12.2999 17.2494C11.6888 17.2494 11.1 16.9851 10.6192 16.6866C10.1149 16.3734 9.60924 15.947 9.13864 15.4646C9.03788 15.3613 8.93778 15.2545 8.83872 15.1445Z" fill="#C4C6D4"/>
        </svg>`,
          nameItem: 'Продвижение',
          open: false,
          hasChild: true,
          hasAI: true,
          hasActiveChild: false,
          childrenItems: [
           /* {
              name: 'Помощник от MamodGPT',
              childURL: '/products',
              // iconComponent: 'AI',
              beta: true,
            },*/
            {
              name: 'Генерация описания',
              childURL: '/generation',
              iconComponent: 'AI',
            },
            {
              name: 'Репрайсер',
              childURL: '/price-tasks',
              class: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'close' : '',
              iconComponent: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'LockIcon' : '',
            },
            /*{
              name: 'Аналитика внешней рекламы',
              childURL: '/external/list/',
            },*/
            {
              name: 'А/В тесты изображений',
              childURL: '/ab-tests',
            },
            // {
            //   name: 'Анализ изменения цены',
            //   childURL: '/price-analysis',
            // },
            {
              name: 'Автоответы на отзывы',
              childURL: '/autoanswers',
              class: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'close' : '',
              iconComponent:
                  this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'LockIcon' : 'AI',
            },
            {
              name: 'Анализ отзывов',
              childURL: '/reviews-analysis',
              iconComponent: 'AI',
            },
          ],
        },
    /*    {
          iconURL: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M17.2091 9.39453C17.9283 9.39453 18.5114 9.9776 18.5114 10.6969L18.5114 17.2085C18.5114 17.9277 17.9283 18.5108 17.2091 18.5108C16.4898 18.5108 15.9067 17.9277 15.9067 17.2085L15.9067 10.6969C15.9067 9.9776 16.4898 9.39453 17.2091 9.39453Z" fill="#C4C6D4"/>
<path d="M6.7906 14.6055C7.50985 14.6055 8.09292 15.1885 8.09292 15.9078V17.2101C8.09292 17.9294 7.50985 18.5124 6.7906 18.5124C6.07135 18.5124 5.48828 17.9294 5.48828 17.2101L5.48828 15.9078C5.48828 15.1885 6.07135 14.6055 6.7906 14.6055Z" fill="#C4C6D4"/>
<path d="M12.0001 12C12.7193 12 13.3024 12.5831 13.3024 13.3023V17.2093C13.3024 17.9285 12.7193 18.5116 12.0001 18.5116C11.2808 18.5116 10.6978 17.9285 10.6978 17.2093L10.6978 13.3023C10.6978 12.5831 11.2808 12 12.0001 12Z" fill="#C4C6D4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.0698 5.48837C16.0698 5.21865 16.2884 5 16.5581 5H17.8605C18.4898 5 19 5.51019 19 6.13953V7.44185C19 7.71157 18.7813 7.93022 18.5116 7.93022C18.2419 7.93022 18.0233 7.71157 18.0233 7.44185V6.63514C15.4948 8.66057 13.5342 9.97638 11.6359 10.8569C9.69436 11.7574 7.84138 12.1917 5.55032 12.4844C5.28278 12.5186 5.03818 12.3294 5.00399 12.0619C4.96981 11.7943 5.15898 11.5497 5.42652 11.5155C7.6559 11.2307 9.40334 10.8156 11.2249 9.97078C12.989 9.15259 14.8443 7.92121 17.2826 5.97674H16.5581C16.2884 5.97674 16.0698 5.75809 16.0698 5.48837Z" fill="#3A416F"/>
</svg>`,
          nameItem: 'Работа с репутацией',
          open: false,
          hasChild: true,
          hasAI: true,
          hasActiveChild: false,
          childrenItems: [

          ],
        },*/

       /* {
          iconURL: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.4995 15.4132V9.12891L12.4995 10.563L6.49951 9.12891V15.4132C6.49951 15.9565 6.82051 16.453 7.32869 16.696L11.8287 18.8472C12.251 19.049 12.748 19.049 13.1703 18.8472L17.6703 16.696C18.1785 16.453 18.4995 15.9565 18.4995 15.4132Z" fill="#3A416F" fill-opacity="0.4"/>
<path d="M18.4995 7.699L13.1703 5.15141C12.748 4.94953 12.251 4.94953 11.8287 5.15141L6.49951 7.699L12.4995 10.5673L18.4995 7.699Z" fill="#3A416F" fill-opacity="0.4"/>
<path d="M18.5 7.70703L12.5 10.5753L14 12.7265L20 9.85824L18.5 7.70703Z" fill="#3A416F"/>
<path d="M6.5 7.70703L12.5 10.5753L11 12.7265L5 9.85824L6.5 7.70703Z" fill="#3A416F"/>
</svg>`,
          nameItem: 'Расчет поставок и закупок',
          open: false,
          hasChild: true,
          hasActiveChild: false,
          childrenItems: [

          ],
        },*/

        {
          iconURL: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M5 10.5556C5 9.96018 5.28501 9.39317 5.78315 8.99759L9.93309 5.70205C11.1118 4.76598 12.8882 4.76598 14.0669 5.70205L18.2168 8.99759C18.715 9.39317 19 9.96018 19 10.5556V16.2206C19 17.7556 17.6071 19 15.8889 19H8.11111C6.39289 19 5 17.7556 5 16.2206V10.5556Z" fill="#3A416F" fill-opacity="0.3"/>
  <path d="M9.66666 16.0846C9.66666 14.4738 10.7113 13.168 12 13.168C13.2887 13.168 14.3333 14.4738 14.3333 16.0846V19.0013H9.66666V16.0846Z" fill="#3A416F"/>
</svg>`,
          nameItem: 'Складской учет',
          open: false,
          hasChild: true,
          hasActiveChild: false,
          childrenItems: [
            {
              name: 'Товары и документы',
              childURL: '/stock/goods',
              class: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'close' : '',
              iconComponent: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'LockIcon' : '',
            },
            {
              name: 'Мои склады',
              childURL: '/stock/list',
              class: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'close' : '',
              iconComponent: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'LockIcon' : '',
            },
         /*   {
              name: 'Категории складских товаров',
              childURL: '/stock/categories',
              class: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'close' : '',
              iconComponent: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'LockIcon' : '',
            },
            {
              name: 'Категории складских документов',
              childURL: '/stock/document-groups',
              class: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'close' : '',
              iconComponent: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'LockIcon' : '',
            },
            {
              name: 'Складские документы',
              childURL: '/stock/documents',
              class: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'close' : '',
              iconComponent: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'LockIcon' : '',
            },*/
            {
              name: 'Склады WB',
              childURL: '/stock/wb-stocks',
              class: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'close' : '',
              iconComponent: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'LockIcon' : '',
            },
            {
              name: 'Планирование закупок',
              childURL: '/stock/procurement-planning',
              class: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'close' : '',
              iconComponent: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'LockIcon' : '',
            },
            {
              name: 'Планирование поставок',
              childURL: '/stock/supply-planning',
              class: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'close' : '',
              iconComponent: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'LockIcon' : '',
            },
          ],
        },
        {
          iconURL: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path opacity="0.4" d="M12 5C13.1512 5 14.2845 5.2839 15.2998 5.82655C16.315 6.3692 17.1807 7.15386 17.8203 8.11101C18.4598 9.06816 18.8535 10.1683 18.9663 11.3139C19.0791 12.4595 18.9077 13.6153 18.4672 14.6788C18.0266 15.7423 17.3306 16.6808 16.4408 17.4111C15.5509 18.1414 14.4947 18.6409 13.3656 18.8655C12.2366 19.0901 11.0696 19.0327 9.96801 18.6986C8.86642 18.3644 7.86425 17.7637 7.05025 16.9497L9.54276 14.4572C9.94686 14.8613 10.4444 15.1595 10.9912 15.3254C11.5381 15.4913 12.1175 15.5198 12.678 15.4083C13.2384 15.2968 13.7628 15.0488 14.2046 14.6863C14.6463 14.3237 14.9918 13.8578 15.2105 13.3298C15.4292 12.8019 15.5143 12.2281 15.4583 11.6594C15.4023 11.0907 15.2069 10.5445 14.8894 10.0694C14.5719 9.5942 14.1421 9.20466 13.6381 8.93527C13.1341 8.66588 12.5715 8.52494 12 8.52494L12 5Z" fill="#3A416F"/>
<path d="M7.05025 16.9497C6.07128 15.9708 5.4046 14.7235 5.1345 13.3656C4.86441 12.0078 5.00303 10.6003 5.53284 9.32122C6.06266 8.04213 6.95986 6.94888 8.11101 6.17971C9.26215 5.41054 10.6155 5 12 5L12 8.52494C11.3127 8.52494 10.6408 8.72875 10.0694 9.11059C9.49789 9.49244 9.05248 10.0352 8.78946 10.6702C8.52645 11.3051 8.45763 12.0039 8.59171 12.678C8.7258 13.352 9.05677 13.9712 9.54276 14.4572L7.05025 16.9497Z" fill="#3A416F"/>
<path d="M19 12C19 12.9193 18.8189 13.8295 18.4672 14.6788C18.1154 15.5281 17.5998 16.2997 16.9497 16.9497L14.4572 14.4572C14.7799 14.1345 15.0359 13.7515 15.2105 13.3298C15.3852 12.9082 15.4751 12.4564 15.4751 12H19Z" fill="#3A416F"/>
</svg>`,
          nameItem: 'Финансы',
          open: false,
          hasChild: true,
          hasActiveChild: false,
          childrenItems: [
            {
              name: 'Сводные показатели',
              childURL: '/internal-analytics/analytics',
              class: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'close' : '',
              iconComponent: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'LockIcon' : '',
            },
            {
              name: 'Доходы и расходы',
              childURL: '/expenses/reports/monthly',
              class: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'close' : '',
              iconComponent: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'LockIcon' : '',
            },
            // {
            //   name: 'Категории расходов',
            //   childURL: '/expenses/categories/main',
            //   class: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'close' : '',
            //   iconComponent: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'LockIcon' : '',
            // },
            {
              name: 'Себестоимость товаров',
              childURL: '/expenses/goods-params',
              class: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'close' : '',
              iconComponent: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'LockIcon' : '',
            },
            {
              name: 'Управленческая отчётность ',
              childURL: '/internal-analytics/reporting',
            },
            {
              name: 'Продажи и заказы',
              childURL: '/internal-analytics/sales-and-orders',
              class: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'close' : '',
              iconComponent: this.userInfo?.current_tariff?.tariff?.title === 'S' ? 'LockIcon' : '',
            },
            {
              name: 'Юнит-калькулятор',
              childURL: '/internal-analytics/unit-calc',
            },
          ],
        },
     /*    {
          iconURL: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.3092 5H7.41365C6.63291 5 6 5.63291 6 6.41365V17.1252C6 18.1043 6.97126 18.7869 7.89249 18.4552L8.99638 18.0578C9.34132 17.9337 9.72101 17.9483 10.0553 18.0988L11.7813 18.8755C12.1503 19.0415 12.5726 19.0415 12.9416 18.8755L14.6676 18.0988C15.0019 17.9483 15.3816 17.9337 15.7265 18.0578L16.8304 18.4552C17.7516 18.7869 18.7229 18.1043 18.7229 17.1252V6.41365C18.7229 5.63291 18.09 5 17.3092 5Z" fill="#3A416F" opacity="0.4"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.4258 8.53433C16.4258 8.82711 16.1884 9.06445 15.8957 9.06445L8.82739 9.06445C8.53461 9.06445 8.29727 8.82711 8.29727 8.53433C8.29727 8.24156 8.53461 8.00421 8.82739 8.00421L15.8957 8.00421C16.1884 8.00421 16.4258 8.24156 16.4258 8.53433Z" fill="#3A416F"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.4258 11.3605C16.4258 11.6533 16.1884 11.8906 15.8957 11.8906L8.82739 11.8906C8.53461 11.8906 8.29727 11.6533 8.29727 11.3605C8.29727 11.0677 8.53461 10.8304 8.82739 10.8304L15.8957 10.8304C16.1884 10.8304 16.4258 11.0677 16.4258 11.3605Z" fill="#3A416F"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8906 14.1886C12.8906 14.4814 12.6533 14.7187 12.3605 14.7187L8.82637 14.7187C8.53359 14.7187 8.29625 14.4814 8.29625 14.1886C8.29625 13.8959 8.53359 13.6585 8.82637 13.6585L12.3605 13.6585C12.6533 13.6585 12.8906 13.8959 12.8906 14.1886Z" fill="#3A416F"/>
                        </svg>`,
          nameItem: 'SEO',
          open: false,
          hasChild: true,
          hasActiveChild: false,
          childrenItems: [
            {
              name: 'Мониторинг позиций',
              childURL: '/positions',
            },
            {
              name: 'Подбор ключевых запросов',
              childURL: '/collection-requests',
            },
            {
              name: 'Сравнение с конкурентами',
              childURL: '/sku',
            },
            {
              name: 'Изменения тренда запросов',
              childURL: '/keywords-list',
            },
            {
              name: 'Анализ карточек товаров',
              childURL: '/product-card/check',
            },
          ],
        },*/
   //     {
        //  iconURL: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
       //                 <path d="M5 7.39998C5 6.62679 5.62679 6 6.39998 6C7.17317 6 7.79996 6.62679 7.79996 7.39998V17.1998C7.79996 17.973 7.17317 18.5998 6.39998 18.5998C5.62679 18.5998 5 17.973 5 17.1998V7.39998Z" fill="#3A416F" opacity="0.4"/>
      //                  <rect x="10.5996" y="9.5" width="2.79996" height="9.09987" rx="1.39998" fill="#3A416F"/>
      //                  <path d="M16.1992 7.39998C16.1992 6.62679 16.826 6 17.5992 6C18.3724 6 18.9992 6.62679 18.9992 7.39998V17.1998C18.9992 17.973 18.3724 18.5998 17.5992 18.5998C16.826 18.5998 16.1992 17.973 16.1992 17.1998V7.39998Z" fill="#3A416F" opacity="0.4"/>
    //                </svg>`,
    //      nameItem: 'Аналитика',
      //    open: false,
    //      hasChild: true,
   //       hasActiveChild: false,
    //      childrenItems: [

            // {
            //   name: "Универсальная таблица товаров",
            //   class:
            //     this.userInfo?.current_tariff?.tariff?.title === "S"
            //       ? "close"
            //       : "",
            //   iconComponent:
            //     this.userInfo?.current_tariff?.tariff?.title === "S"
            //       ? "LockIcon"
            //       : "",
            //   childURL: "/wb-products/table",
            // },
            // {
            //   name: "Параметры таблицы",
            //   childURL: "/wb-products/table-params",
            //   class:
            //     this.userInfo?.current_tariff?.tariff?.title === "S"
            //       ? "close"
            //       : "",
            //   iconComponent:
            //     this.userInfo?.current_tariff?.tariff?.title === "S"
            //       ? "LockIcon"
            //       : "",
            // },
            // {
            //   name: "Пресеты параметров",
            //   childURL: "/wb-products/presets",
            //   class:
            //     this.userInfo?.current_tariff?.tariff?.title === "S"
            //       ? "close"
            //       : "",
            //   iconComponent:
            //     this.userInfo?.current_tariff?.tariff?.title === "S"
            //       ? "LockIcon"
            //       : "",
            // },
         // ],
     //   },

        // {
        //   iconURL: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        //                 <path d="M5 7.39998C5 6.62679 5.62679 6 6.39998 6C7.17317 6 7.79996 6.62679 7.79996 7.39998V17.1998C7.79996 17.973 7.17317 18.5998 6.39998 18.5998C5.62679 18.5998 5 17.973 5 17.1998V7.39998Z" fill="#3A416F" opacity="0.4"/>
        //                 <rect x="10.5996" y="9.5" width="2.79996" height="9.09987" rx="1.39998" fill="#3A416F"/>
        //                 <path d="M16.1992 7.39998C16.1992 6.62679 16.826 6 17.5992 6C18.3724 6 18.9992 6.62679 18.9992 7.39998V17.1998C18.9992 17.973 18.3724 18.5998 17.5992 18.5998C16.826 18.5998 16.1992 17.973 16.1992 17.1998V7.39998Z" fill="#3A416F" opacity="0.4"/>
        //             </svg>`,
        //   nameItem: "Внутренняя аналитика",
        //   open: false,
        //   hasChild: true,
        //   hasActiveChild: false,
        //   childrenItems: [
        //     // {
        //     //   name: "География заказов",
        //     //   childURL: "/internal-analytics/geo-map",
        //     //   class:
        //     //     this.userInfo?.current_tariff?.tariff?.title === "S"
        //     //       ? "close"
        //     //       : "",
        //     //   iconComponent:
        //     //     this.userInfo?.current_tariff?.tariff?.title === "S"
        //     //       ? "LockIcon"
        //     //       : "",
        //     // },

        //     // {
        //     //   name: "Универсальная таблица товаров",
        //     //   class:
        //     //     this.userInfo?.current_tariff?.tariff?.title === "S"
        //     //       ? "close"
        //     //       : "",
        //     //   iconComponent:
        //     //     this.userInfo?.current_tariff?.tariff?.title === "S"
        //     //       ? "LockIcon"
        //     //       : "",
        //     //   childURL: "/wb-products/table",
        //     // },
        //     // {
        //     //   name: "Параметры таблицы",
        //     //   childURL: "/wb-products/table-params",
        //     //   class:
        //     //     this.userInfo?.current_tariff?.tariff?.title === "S"
        //     //       ? "close"
        //     //       : "",
        //     //   iconComponent:
        //     //     this.userInfo?.current_tariff?.tariff?.title === "S"
        //     //       ? "LockIcon"
        //     //       : "",
        //     // },
        //     // {
        //     //   name: "Пресеты параметров",
        //     //   childURL: "/wb-products/presets",
        //     //   class:
        //     //     this.userInfo?.current_tariff?.tariff?.title === "S"
        //     //       ? "close"
        //     //       : "",
        //     //   iconComponent:
        //     //     this.userInfo?.current_tariff?.tariff?.title === "S"
        //     //       ? "LockIcon"
        //     //       : "",
        //     // },
        //   ],
        // },
        // {
        //   iconURL: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        //                     <path d="M17.2086 9.39453C17.9278 9.39453 18.5109 9.9776 18.5109 10.6969L18.5109 17.2085C18.5109 17.9277 17.9278 18.5108 17.2086 18.5108C16.4893 18.5108 15.9062 17.9277 15.9062 17.2085L15.9063 10.6969C15.9063 9.9776 16.4893 9.39453 17.2086 9.39453Z" fill="#3A416F" opacity="0.4"/>
        //                     <path d="M6.7906 14.6055C7.50985 14.6055 8.09292 15.1885 8.09292 15.9078V17.2101C8.09292 17.9294 7.50985 18.5124 6.7906 18.5124C6.07135 18.5124 5.48828 17.9294 5.48828 17.2101L5.48828 15.9078C5.48828 15.1885 6.07135 14.6055 6.7906 14.6055Z" fill="#3A416F" opacity="0.4"/>
        //                     <path d="M11.9996 12C12.7188 12 13.3019 12.5831 13.3019 13.3023V17.2093C13.3019 17.9285 12.7188 18.5116 11.9996 18.5116C11.2803 18.5116 10.6973 17.9285 10.6973 17.2093L10.6973 13.3023C10.6973 12.5831 11.2803 12 11.9996 12Z" fill="#3A416F" opacity="0.4"/>
        //                     <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0698 5.48837C16.0698 5.21865 16.2884 5 16.5581 5H17.8605C18.4898 5 19 5.51019 19 6.13953V7.44185C19 7.71157 18.7813 7.93022 18.5116 7.93022C18.2419 7.93022 18.0233 7.71157 18.0233 7.44185V6.63514C15.4948 8.66057 13.5342 9.97638 11.6359 10.8569C9.69436 11.7574 7.84138 12.1917 5.55032 12.4844C5.28278 12.5186 5.03818 12.3294 5.00399 12.0619C4.96981 11.7943 5.15898 11.5497 5.42652 11.5155C7.6559 11.2307 9.40334 10.8156 11.2249 9.97078C12.989 9.15259 14.8443 7.92121 17.2826 5.97674H16.5581C16.2884 5.97674 16.0698 5.75809 16.0698 5.48837Z" fill="#3A416F"/>
        //                 </svg>`,
        //   nameItem: 'Внешняя аналитика',
        //   open: false,
        //   hasChild: true,
        //   hasActiveChild: false,
        //   childrenItems: [
        //     {
        //       name: 'Анализ категорий',
        //       childURL: '/external-analytics/categories',
        //     },
        //     {
        //       name: 'Анализ магазинов',
        //       childURL: '/external-analytics/store',
        //     },
        //     {
        //       name: 'Анализ брендов',
        //       childURL: '/external-analytics/brands',
        //     },
        //     {
        //       name: 'Товары',
        //       childURL: '/external-analytics/goods',
        //     },
        //   ],
        // },
        // {
        //   iconURL: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        //                     <path d="M1.16602 5.9043L1.16602 10.6455C1.16602 11.9342 2.21068 12.9789 3.49935 12.9789H10.4993C11.788 12.9789 12.8327 11.9342 12.8327 10.6455V5.90433L8.40107 7.34193C8.40107 7.34193 7.8751 8.76434 6.99935 8.76433C6.1236 8.76433 5.47724 7.34193 5.47724 7.34193L1.16602 5.9043Z" fill="#3A416F" opacity="0.4"/>
        //                     <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4993 3.64453H3.49935C2.21068 3.64453 1.16602 4.6892 1.16602 5.97786V6.18056C1.16602 6.18056 2.86583 7.31282 5.54004 7.64453V7.91104C5.54004 8.55537 6.06237 9.07771 6.70671 9.07771H7.29204C7.93637 9.07771 8.4587 8.55537 8.4587 7.91104V7.64453C11.1329 7.31281 12.8327 6.18056 12.8327 6.18056V5.97786C12.8327 4.6892 11.788 3.64453 10.4993 3.64453ZM6.45993 7.19995C6.39549 7.19995 6.34326 7.25218 6.34326 7.31662V7.89343C6.34326 8.15116 6.5522 8.36009 6.80993 8.36009H7.18889C7.44663 8.36009 7.65556 8.15116 7.65556 7.89343V7.31662C7.65556 7.25218 7.60333 7.19995 7.53889 7.19995H6.45993Z" fill="#3A416F"/>
        //                     <path fill-rule="evenodd" clip-rule="evenodd" d="M6.41602 1.75C5.69114 1.75 5.10352 2.33763 5.10352 3.0625V3.64583C5.10352 3.88746 4.90764 4.08333 4.66602 4.08333C4.42439 4.08333 4.22852 3.88746 4.22852 3.64583V3.0625C4.22852 1.85438 5.20789 0.875 6.41602 0.875H7.58268C8.7908 0.875 9.77018 1.85438 9.77018 3.0625V3.64583C9.77018 3.88746 9.57431 4.08333 9.33268 4.08333C9.09106 4.08333 8.89518 3.88746 8.89518 3.64583V3.0625C8.89518 2.33763 8.30756 1.75 7.58268 1.75H6.41602Z" fill="#3A416F"/>
        //                 </svg>`,
        //   nameItem: "Управление рекламой",
        //   open: false,
        //   hasChild: true,
        //   hasActiveChild: false,
        //   childrenItems: [
        //     {
        //       name: "Актуальные ставки",
        //       childURL: "/actual-rates",
        //     },
        //     {
        //       name: "Автобиддер",
        //       childURL: "/ad-cabinet",
        //     },
        //   ],
        // },
      ];
    },
    getUserImage() {
      const userImage = 'userpick';
      return require(`@/assets/img/${userImage}.png`);
    },
  },
  watch: {
    $route: {
      handler(val, newVal) {
        if (newVal.path !== val.path) this.$emit('input', false);
      },
      deep: true,
    },
    showNotifications() {
      if (!this.showNotifications) this.getNotifications();
    },
  },

  mounted() {
    this.$nextTick(this.fillList);
    this.$parent.$on('showModal', this.editModalVisible);
    document.body.addEventListener('click', () => (this.showNotifications = false));
  },
  beforeDestroy() {
    this.$parent.$off('showModal', this.handleAction);
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/mixin.scss';
circle,
path {
  transition: all 0.3s;
}

.v-application .sidebar {
  z-index: 101;
  &-footer {
    padding: 0;
    &__wrapper {
      justify-content: flex-start;
      gap: 10px;
      margin-top: 10px;
    }
  }
  &__oldDesignLink {
    padding: 15.5px 22px;

    a {
      display: flex;
      align-items: center;
      gap: 13px;

      font-family: 'Manrope';
      font-weight: 600;
      font-size: 13px;
      letter-spacing: -0.4px;

      opacity: 0.6;
    }
  }
  &__navigation {
    &-icon {
      width: 24px;
      height: 24px;
    }
    &-ai {
      position: absolute;
      top: 54%;
      right: 28px;
      transform: translate(0, -50%);
      transition: all 200ms ease;
      margin: 0;
      &.hasChild {
        right: 28px;
      }
    }
  }
  &__limits {
    &-card {
      text-align: right;
    }
    &-cardValues {
      justify-content: flex-end;
      span {
        text-align: right;
      }
    }
  }
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  background: white;
  overflow-y: auto;
  min-width: $sidebar-width;
  box-sizing: border-box;
  // border: 1px solid #000;
  padding-top: 21px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  @include customScrollbar;

  @media (max-width: 450px) {
    width: 100vw;

    &__limits {
      left: 20% !important;
    }

    &__notifications {
      left: 10% !important;
    }
  }

  &__closeBtn {
    transform: rotate(45deg);
    position: absolute;
    right: 30px;
    top: 30px;
  }

  &__total-wrapper {
    flex: 1 0 auto;
  }
  &__container {
    padding-left: $sidebar-padding;
  }
  &__logo img {
    width: 200px;
  }
  &__user {
    background: #f8f9ff;
    padding: 14px 14.5px;
    margin: 10px;
    border-radius: 16px;
  }
  &__user-image {
    width: 77px;
    height: 77px;
    border-radius: 50%;
  }
  &__user-info {
    .sidebar__user-name {
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.02em;
      color: #3a416f;
      margin-bottom: 6px;
    }
    .sidebar__user-rate {
      font-weight: 500;
      font-size: 13px;
      line-height: 12px;
      letter-spacing: -0.4px;
      color: #3a416f;
      opacity: 0.6;
    }
    .sidebar__user-buttons {
      display: flex;
      margin-top: 16px;
      gap: 6px;
    }
    .sidebar__user-button {
      @include flex;
      justify-content: center;
      flex: 1 0;
      font-family: 'Manrope';
      font-weight: 600;
      font-size: 12px;
      letter-spacing: -0.02em;
      color: #3a416f;

      background: rgba(58, 65, 111, 0.1);
      border-radius: 6px;
      padding: 8px;
      border: none;
      cursor: pointer;
      gap: 7.75px;
    }
    .sidebar__small-button {
      @include flex;
      justify-content: center;
      width: 34px;
      height: 34px;
      background: rgba(58, 65, 111, 0.1);
      border-radius: 50%;
      transition: all 0.3s ease;

      path {
        transition: all 0.3s ease;
      }

      &.hasNewNotifications {
        background: #3586ff45;

        path {
          fill: #3586ff;
        }
      }
    }

    .sidebar__user-limitsBtn {
      margin-top: 6px;

      & > .button {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }

  &__limits {
    width: 230px;
    background: #e9eaeb;
    border-radius: 12px;
    position: fixed;
    left: 17.25rem;
    top: 50%;
    transform: translateY(-50%);
    padding: 6px;
    z-index: 1;

    h5 {
      font-size: 13px;
      line-height: 13px;
    }

    & > h5 {
      padding: 6px 6px 0;
    }

    &-card {
      background: #ffffff;
      border-radius: 7px;
      margin-top: 6px;
      padding: 12px 10px 10px;

      h5 {
        margin-bottom: 6px;
      }

      &Values {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 6px;

        span {
          font-weight: 600;
          font-size: 12px;
          letter-spacing: -0.4px;
          opacity: 0.6;
        }
      }
    }
  }

  &__notifications {
    width: 80%;
    max-width: 400px;
    background: #eaebec;
    border-radius: 12px;
    position: fixed;
    left: 17.25rem;
    top: 150px;
    padding: 10px;
    box-shadow: 0px 0px 7px #0000003d;
    overflow: auto;
    max-height: 300px;

    &.empty {
      padding: 7.5px;
    }

    &-listItem {
      position: relative;
      background: #fff;
      padding: 10px;
      border-radius: 12px;
      margin-bottom: 9px;
      display: flex;
      flex-direction: column;
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 0px 7px #0000003d;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.isNotRead {
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 10px;
          right: 10px;
          width: 8px;
          height: 8px;
          background: #ff0079;
          border-radius: 50%;
        }
      }
    }

    &-message {
      font-weight: 600;
      font-size: 0.85em;
      line-height: 1.3em;
    }

    &-date {
      margin-left: auto;
      font-size: 0.7em;
      font-family: 'Manrope';
      font-weight: 600;
      letter-spacing: -0.4px;
      color: #3a416f;
      opacity: 0.6;
    }

    &-empty {
      font-weight: 700;
      background: #fff;
      padding: 8px;
      border-radius: 12px;
    }
  }
}

.sidebar__navigation {
  padding: 0;
  display: flex;
  flex-direction: column;

  &-title-box {
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: -0.4px;
    color: #3a416f;
    display: flex;
    align-items: center;
    padding: 10px 0 10px $sidebar-padding;
    position: relative;
  }
  &-title {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #797fa8;
    padding-left: 15px;
    opacity: 0.5;
    margin: 20px 0 10px;
  }
  &-item {
    font-family: 'Open Sans';
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: -0.4px;
    color: #3a416f;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    transition: all 0.3s;

    path,
    circle {
      transition: all 0.3s ease;
    }

    & > div {
      transition: all 0.3s ease;
    }

    & > div:hover {
      color: $prime-color;

      .sidebar__navigation-icon path,
      .sidebar__navigation-icon circle {
        fill: $prime-color;
      }

      .arrow path {
        stroke: $prime-color;
      }
    }

    &.active {
      color: $prime-color;

      .sidebar__navigation-title-box {
        path,
        circle {
          fill: $prime-color;
        }
      }

      & > div {
        color: $prime-color;
      }

      .arrow path {
        stroke: $prime-color;
      }
    }

    .arrow {
      position: absolute;
      top: 54%;
      right: 24px;
      transform: translate(0, -50%);
      transition: all 200ms ease;
    }
    &.open .arrow {
      transform: rotate(90deg) translateX(-100%);
    }
  }

  &-item-box {
    display: flex;
    align-items: center;
  }
  .drop-down::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translate(0, -50%) rotate(135deg);
    right: 28px;
    width: 8px;
    height: 8px;
    border: 0.4px solid #3a416f;
    border-width: 1px 0 0 1px;
  }
  &-child-box {
    list-style: none;
    display: none;
    background: #f4f7fb;
    box-shadow: 0px 1px 0px rgba(233, 235, 244, 0.5);
    a {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 13px;
      letter-spacing: -0.4px;
      color: #3a416f;
      display: flex;
      transition: all 0.3s;
      text-decoration: none;
      max-width: 255px;
      max-height: 38px;
    }
  }
  &-child-item {
    padding: 12.5px $sidebar-padding 12.5px;
    transition: all 0.3s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    color: #3a416f;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
    letter-spacing: -0.174px;

    path {
      fill: #3a416f;
    }

    &:hover {
      background: #f7f7f7;
    }
    &.active {
      color: #3586ff !important;

      path {
        // stroke: #3586ff;
        fill: $prime-color;
      }
    }
    &.close {
      opacity: 0.3;
      path {
        fill: #3a416f;
      }
    }
  }
  &-icon {
    min-height: 24px;
    min-width: 24px;
    margin-right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.open .sidebar__navigation-child-box {
  display: block;
}
.sidebar-group {
  border-bottom: 1px solid rgba(233, 235, 244, 0.5);
  .v-list-group__header {
    padding: 0 20px;
  }
  &.v-list-group--active {
    // transition: all .8s;
    border-left: 3px solid #3a416f;
    margin-right: -3px;
  }
  .list-item__wrapper {
    flex-direction: row;
  }
  .list-item__icon {
    width: 14px;
    height: 14px;
  }
  .list-title {
    font-family: 'Open Sans';
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: -0.4px;
    color: #3a416f;
    margin-left: 11px;
    &.child-item {
      margin-left: 30px;
      display: block;
      width: 100%;
      text-decoration: none;
    }
  }
  .item-child {
    border-bottom: 1px solid rgba(233, 235, 244, 0.5);
  }
  .v-list-item__content > * {
    flex: none;
  }
  .v-icon.v-icon {
    transform: rotate(-90deg);
  }
}
.sidebar {
  &-footer {
    // background: url('~@/assets/img/tg.png') right/auto 100% no-repeat;
    flex-shrink: 0;
    padding: 15px 0 15px 20px;

    &__title {
      font-family: 'Open Sans';
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.4px;
      color: #3a416f;
    }
    &__description {
      font-family: 'Open Sans';
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      color: #3a416f;
      opacity: 0.7;
      margin: 5px 0 10px;
    }
    &__button {
      background: #2ea7e4;
      border-radius: 6px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 13px 9px 14px;
      box-sizing: border-box;
      max-width: 97px;
      transition: all 0.3s;
      &:hover {
        background: #79d1fd;
      }
      a {
        font-family: 'Open Sans';
        font-weight: 600;
        text-align: center;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: -0.02em;
        transition: all 0.3s;
        color: #fff7fb;
        text-decoration: none;
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    &__item {
      justify-content: flex-start;
      gap: 6px;
      padding-right: 20px;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        color: #3586ff;
        .sidebar__navigation-icon path,
        .sidebar__navigation-icon circle {
          fill: $prime-color;
        }
      }
    }
  }
  &__navigation {
  }
}
</style>
